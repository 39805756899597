.CompanyCard {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  color: #292933;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;

  > div {
    font-family: 'Circular Std Bold', serif !important;
    word-break: break-all;

    .more-icon {
      padding: 3px 5px !important;
      border-radius: 4px !important;
      background-color: #ffffff !important;
      margin-left: auto;

      &:focus {
        background-color: #ffffff !important;
        box-shadow: 0 0 0 3px rgb(130 138 145 / 30%) !important;
      }
    }
  }
}
