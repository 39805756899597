.CompanyPage {
  background-color: #f0f3f7;
  min-height: 100vh;
  padding-top: 30px;
  padding-bottom: 30px;

  .side-info {
    background-color: #fff;
    border-radius: 8px;
    padding: 24px;
    height: max-content;
  }

  .info-container {
    .load-more-container {
      padding: 16px 24px;

      > button {
        width: 100%;
        border-radius: 14px;
        outline: none;
        box-shadow: none;
        font-family: CircularXXWeb-Bold, sans-serif;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
      }
    }
  }
}
