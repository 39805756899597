.UserLayout {
  background-color: #f0f3f7;
  min-height: 100vh;
  .container {
  }
  .side-bar-container {
    width: 24%;
  }

  .content-container {
    width: 76%;

    &.not-allowed {
      width: 100%;
    }
  }
}
