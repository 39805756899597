.Sidebar {
  background-color: #ffffff;
  border-radius: 15px;
  margin-top: 1rem;
  padding: 0.5rem;
  width: 100%;

  .menu-item {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: left;
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    cursor: pointer;
    font-size: 17px;

    &.active {
      background-color: #e6f0ff;
      border-radius: 15px;
      color: #006aff;
    }

    .menu-item-text {
      font-family: CircularXXWeb-Bold, serif;
    }

    svg {
      margin-right: 5px;
    }

    @media (min-width: 780px) {
      svg {
        margin-right: 15px;
      }
    }
  }
}
