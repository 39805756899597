.CompanyUsersDetails {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 30px;

  .header {
    color: #292933;
    font-family: CircularXXWeb-Bold, serif;
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 29px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
  }

  .key-labels {
    background-color: #fafafa;
    border-top: 1px solid #e1e1e6;
    border-bottom: 1px solid #e1e1e6;
    padding: 18px 24px;
    display: flex;
    flex-direction: row;
    width: 100%;

    .key {
      width: 25%;
      font-family: CircularXXWeb-Black, sans-serif;
      font-size: 12px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 20px;
      color: #838299;
      text-transform: uppercase;
      margin-bottom: 0;
      cursor: pointer;

      > svg {
        font-size: 16px;
      }

      &.name {
        width: 20%;
      }

      &.last-login {
        width: 20%;
      }

      &.average-activity-time {
        width: 20%;
      }

      &.most-visited-pages {
        width: 20%;
      }

      &.status {
        width: 20%;
        text-align: right;
      }
    }
  }

  .items-container {
    padding-left: 24px;
    padding-right: 24px;

    .item {
      display: flex;

      .single {
        color: #292933;
        font-family: CircularXXWeb-Bold, sans-serif;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 19px;
        margin-bottom: 0;
        padding-bottom: 31.5px;
        padding-top: 31.5px;
        border-bottom: 1px solid #e1e1e6;

        &.name {
          width: 20%;
        }

        &.last-login {
          width: 20%;
        }

        &.average-activity-time {
          width: 20%;
        }

        &.most-visited-pages {
          width: 20%;
        }

        &.status {
          width: 20%;
          text-align: right;
        }
      }
    }
  }
}
