.LoginPage {
  display: flex;
  height: 100vh;
  width: 100vw;
  background: white;

  @media screen and (max-width: 768px) {
    display: block;
    .quote-container {
      width: 100% !important;
      margin: 0 auto;
      height: max-content !important;
      display: none !important;
      //order: 2
    }
    .login-container {
      width: 100% !important;
      margin: 0 auto;
      padding: 30px !important;
      order: 1;
    }
  }

  .quote-container {
    width: 60%;
    height: 100%;
    background-color: #e5f0ff;
    padding: 0 100px;
    display: flex;
    align-items: center;

    .title {
      background-color: #006aff;
      color: white;
      font-size: 23px;
      padding: 10px 20px;
      text-align: center;
      width: max-content;
    }

    .quote {
      color: #006aff;
      font-size: 32px;
      line-height: 48px;
      font-family: 'CircularXXWeb-Bold', serif;
      margin-top: 30px;
    }

    .sub-quote {
      color: #006aff;
      font-size: 16px;
      line-height: 120%;
      font-family: 'CircularXXWeb-Book', serif;
    }
  }

  .login-container {
    width: 40%;
    height: 100%;
    padding: 0 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      width: 100%;
      max-width: 400px;
    }

    .logo {
      width: 200px;
      margin-bottom: 55px;
    }

    .title {
      font-size: 40px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 48px;
      position: relative;
      margin-left: 15px;
      margin-bottom: 24px;

      .label {
        position: relative;
        z-index: 100;
        font-family: 'CircularXXWeb-Black', serif;
      }

      .icon-container {
        width: 50px !important;
        height: 50px !important;
        position: absolute;
        margin-top: -22px;
        margin-left: -10px;
        z-index: 1;

        .cliff-icon {
          width: 100%;
          height: 100%;
        }
      }
    }

    .input-container {
      margin-bottom: 16px;
      position: relative;

      .form-title {
        margin-bottom: 3px;
        font-size: 14px;
        font-family: 'CircularXXWeb-Bold', serif;
        margin-left: 3px;

        svg {
          margin-bottom: 2px;
          margin-right: 3px;
        }
      }

      .password-toggle-container {
        position: absolute;
        top: 50%;
        right: 15px;
        cursor: pointer;

        svg {
          color: #979797;
          font-size: 16px;
        }
      }
    }

    .remember-container {
      display: flex;
      justify-content: space-between;

      .checkbox-container {
        display: flex;
        align-items: center;
        margin-right: 10px;

        .checkbox {
          width: 20px;
          height: 20px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 5px;
          cursor: pointer;
          border: 2px solid #bcbcbc;
          background: #fafafa;
        }

        .checkbox-label {
          font-size: 14px;
          font-family: 'CircularXXWeb-Bold', serif;
          margin-bottom: 0;
        }
      }

      .forgot-container {
        display: flex;
        align-items: center;
        margin-left: 10px;

        .forgot-label {
          font-size: 14px;
          font-family: 'CircularXXWeb-Bold', serif;
          margin-bottom: 0;
          color: #006aff;
          cursor: pointer;
        }
      }
    }

    .login-btn {
      width: 100%;
      height: 50px;
      border-radius: 8px;
      background-color: #fc9e40 !important;
      color: white !important;
      font-size: 16px;
      font-family: 'CircularXXWeb-Bold', serif;
      margin-top: 24px;
      cursor: pointer;
      border-color: #fc9e40 !important;

      &:hover,
      &:active {
        background-color: #f57c00 !important;
        border-color: #fc9e40 !important;
      }
    }

    .signup-container {
      margin-top: 24px;
      display: flex;

      p {
        font-size: 14px;
        font-family: 'CircularXXWeb-Bold', serif;
        margin-bottom: 0;
      }

      .text2 {
        color: #006aff;
        margin-left: 5px;
        cursor: pointer;
      }
    }

    .error-container {
      p {
        margin-bottom: 0;
        font-family: 'CircularXXWeb-Bold', serif;
        color: #eb4335;
      }
    }
  }
}
