/* 01.Base */
@font-face {
  font-family: 'CircularXXWeb-Book';
  src: url('./shared/assets/fonts/CircularXXSub-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'CircularXXWeb-Bold';
  src: url('./shared/assets/fonts/CircularXXWeb-Bold.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXWeb-Bold';
  src: url('./shared/assets/fonts/CircularXXWeb-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXWeb-Book';
  src: url('./shared/assets/fonts/CircularXXWeb-Book.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXWeb-Book';
  src: url('./shared/assets/fonts/CircularXXWeb-Book.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular Std Black';
  src: url('./shared/assets/fonts/CircularXXWeb-Black.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXWeb-Black';
  src: url('./shared/assets/fonts/CircularXXWeb-Black.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular Std Bold';
  src: url('./shared/assets/fonts/CircularXXWeb-Bold.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXWeb-Bold';
  src: url('./shared/assets/fonts/CircularXXWeb-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular Std Book';
  src: url('./shared/assets/fonts/CircularXXWeb-Book.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXWeb-Book';
  src: url('./shared/assets/fonts/CircularXXWeb-Book.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXWeb-Black';
  src: url('./shared/assets/fonts/CircularXXWeb-Black.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXWeb-Black';
  src: url('./shared/assets/fonts/CircularXXWeb-Black.woff2') format('woff2');
}

/* subset */

@font-face {
  font-family: 'CircularXXSub-Bold';
  src: url('./shared/assets/fonts/CircularXXSub-Bold.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXSub-Bold';
  src: url('./shared/assets/fonts/CircularXXSub-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXSub-Book';
  src: url('./shared/assets/fonts/CircularXXSub-Book.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXSub-Book';
  src: url('./shared/assets/fonts/CircularXXSub-Book.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXSub-Black';
  src: url('./shared/assets/fonts/CircularXXSub-Black.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXSub-Black';
  src: url('./shared/assets/fonts/CircularXXSub-Black.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXSub-Bold';
  src: url('./shared/assets/fonts/CircularXXSub-Bold.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXSub-Bold';
  src: url('./shared/assets/fonts/CircularXXSub-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXSub-Book';
  src: url('./shared/assets/fonts/CircularXXSub-Book.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXSub-Book';
  src: url('./shared/assets/fonts/CircularXXSub-Book.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXSub-Black';
  src: url('./shared/assets/fonts/CircularXXSub-Black.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXSub-Black';
  src: url('./shared/assets/fonts/CircularXXSub-Black.woff2') format('woff2');
}

* {
  font-family: 'CircularXXWeb-Book', serif;
}

.f-std-black {
  font-family: 'Circular Std Black', serif !important;
}

.f-std-bold {
  font-family: 'Circular Std Bold', serif !important;
}

.glob-font,
.f-std-book {
  font-family: 'Circular Std Book', serif !important;
}

/* 03.Widths-Spacing */
.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.w-12 {
  width: 12% !important;
}

.w-13 {
  width: 13% !important;
}

.w-88 {
  width: 88% !important;
}

.w-15 {
  width: 15% !important;
}

.w-85 {
  width: 85% !important;
}

.w-20 {
  width: 20% !important;
}

.w-80 {
  width: 80% !important;
}

.w-25 {
  width: 25% !important;
}

.w-75 {
  width: 75% !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-33 {
  width: 33.33% !important;
}

.ml-auto {
  margin-left: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

strong {
  font-family: 'Circular Std Black', serif;
}

.btn {
  border-radius: 14px !important;
  padding: 10px 22px !important;
  font-family: 'Circular Std Bold', serif;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;

  &.btn-secondary {
    border-radius: 14px !important;
    background-color: #e1e1e6 !important;
    border: none;
    color: #838299;

    &:hover,
    &:focus {
      color: #838299;
      background-color: #d4d4d7 !important;
    }
  }
}

.modal-content {
  border-radius: 12px !important;
  background-color: #ffffff !important;

  .modal-header {
    padding: 40px 40px 20px 40px;
    border-bottom: 0;

    .modal-title {
      color: #292933;
      font-family: 'Circular Std Black', serif;
      font-size: 24px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 29px;
    }
  }

  .modal-body {
    padding: 20px 40px 20px 40px;
  }

  .modal-footer {
    padding: 10px 40px 40px 40px;
    border-top: 0;
  }
}

label {
  &.form-label {
    color: #292933;
    font-family: 'Circular Std Black', serif;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 16px;
  }
}

input {
  &.form-control {
    border-radius: 8px;
    border-color: #e1e1e6;
    background-color: #fafafa;
    color: #212529;
    padding: 12px 14px;
  }
}

.dropdown-menu {
  &.show {
    border: 0;
    padding: 0;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;

    .dropdown-item {
      padding: 15px;
      color: #292933;
      font-family: 'Circular Std Book', serif;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 24px;
      border-bottom: 1px solid #e1e1e6;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
