.StickyHeader {
  position: relative;
  height: 3rem; /* We need to change this value */
  &.sticky {
    .sticky-inner {
      position: fixed;
      top: 160px;
      //left: 160px;
      //right: 160px;
      z-index: 101;
    }
  }
}
