.PageSplitterContainer {
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  color: #838299;
  padding: 0.5rem;
  margin: 1rem 0;

  button {
    border: none;
    background-color: #fff;
    color: #838299;
    border-radius: 5px;
    flex: 1;
    text-align: center;
    padding: 0.5rem 0;
    font-weight: 700;
  }

  button.isActive {
    background-color: #006aff;
    color: white;
  }
}
