.CompaniesPage {
  .page-title {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Circular Std Black', serif;
    color: #292933;
    font-size: 40px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 48px;
  }

  .head {
    padding: 20px;
    gap: 0.5rem;

    .title {
      margin-bottom: 0;
      font-family: 'Circular Std Black', serif;
      color: #838299;
      font-size: 14px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 20px;
    }
  }

  .StickyHeader.sticky .head {
    background-color: #fafafa;
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
