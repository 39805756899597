.Header {
  background-color: white;
  height: 72px;
  display: flex;
  padding-left: 0;

  .logo-container {
    position: relative;
    left: -60px;
    padding-right: 15px;
    > .logo {
      width: 160px;
      margin-top: -12px;
    }
  }

  .action-container {
    margin-left: auto;
    margin-top: -12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    right: -45px;
  }

  .header-left {
    .avatar {
      > img {
        width: 40px;
        height: 40px;
        border-radius: 14px;
        margin-right: 10px;
      }
    }

    .info {
      align-self: center;

      .name {
        color: #292933;
        font-family: 'Circular Std Bold', serif;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 19px;
      }
    }
  }
}
