.CompanyTopBarDetails {
  .logo-title-container {
    position: relative;
    display: flex;

    .logo-container {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 100px;
        width: 100px;
        border-radius: 36px;
      }
    }

    .info-container {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }

    .title-container {
      margin-top: 16px;

      .name {
        font-size: 20px;
        line-height: 16px;
      }

      p {
        color: #292933;
        font-family: CircularXXWeb-Bold, sans-serif;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 10px;

        span {
          color: gray;
        }
      }
    }
  }

  .key-label {
    .key {
      color: #292933;
      font-family: CircularXXWeb-Black, sans-serif;
      font-size: 14px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 16px;
      margin-bottom: 0;
    }

    .label {
      color: #838299;
      font-family: CircularXXWeb-book, sans-serif;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 0;
      margin-top: 4px;
      word-break: break-all;
    }
  }
}

.btn-group {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  .isActive {
    background-color: #13bf9430 !important;
    color: #13bf94;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 5px;
    &:hover,
    &:focus {
      background-color: #13bf9430 !important;
      color: #13bf94;
    }
  }

  .isRejected {
    background-color: #f24949 !important;
    color: white;

    &:hover {
      background-color: #ca3c3c !important;
      color: white;
    }
  }

  .isDeleted {
    background-color: #f24949 !important;
    color: white;

    &:hover {
      background-color: #ca3c3c !important;
      color: white;
    }
  }

  .isSuspended {
    &:hover {
      color: #fff;
    }
  }
}
