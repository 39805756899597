.SendEmailModal {
  .quill {
    height: 250px;
    margin-bottom: 30px;

    .ql-toolbar {
      border-color: #e1e1e6;
    }

    .ql-container {
      border-color: #e1e1e6;
    }
  }
}
