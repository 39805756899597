.BackButton {
  width: fit-content;
  & p {
    color: #838299;
    cursor: pointer;
    font-size: 16px;
  }
}

.GoBackContainer {
  display: flex;
  align-items: center;
  color: #006aff !important;
  font-size: 0.8rem;
  font-weight: 400;
}
